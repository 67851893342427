import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './Card.module.scss'

import Button from 'basics/Button'
import Image from 'basics/Image'
import { ClassNames } from 'services/classname.service'
import { PickFirst } from 'services/utils'

const CardTypes = {
  image: 'image',
  icon: 'icon',
  location: 'location',
  normal: 'normal',
  logo: 'logo',
  flexible: 'flexible',
  news: 'news',
  map: 'map'
}

const Card = ({
  className,
  children,
  image,
  imageAlt = '',
  title,
  subtitle,
  description,
  type = 'icon',
  withCta = false,
  embed,
  embedTitle,
  ...theRest
}) => {
  if (Array.isArray(image)) {
    const tmp = PickFirst(image)
    if (tmp) {
      image = typeof tmp === 'string' ? tmp : tmp?.fileName
    }
  }

  return (
    <article
      className={ClassNames([
        styles['ds-card'],
        styles[CardTypes[type]],
        withCta ? styles['ds-card--with-cta'] : '',
        subtitle && !embed ? styles['article'] : '',
        className
      ])}
      {...theRest}
    >
      {type !== 'logo' && (
        <>
          {type === 'image' && image && (
            <div className={styles['ds-card__image']}>
              <Image src={image} alt={imageAlt} imageProps={{ width: 323 }} />
            </div>
          )}
          {embed && (
            <div className={styles['ds-card__image']}>
              <iframe
                src={`https://snazzymaps.com/embed/${embed}`}
                width="100%"
                height="140"
                border="none"
                className={styles['ds-map-image']}
                title={`Map to ${title || embedTitle} location`}
                loading="lazy"
              />
            </div>
          )}
          <div className={styles['ds-card__text']}>
            {subtitle && (
              <p className={ClassNames([styles['ds-card__text-subtitle'], 'font-weight-bold'])}>
                {subtitle}
              </p>
            )}
            {title && <h3 className={ClassNames([`font-weight-bold`])}>{title}</h3>}
            {children && <div className={styles['ds_card__content']}>{children}</div>}
            {type === 'logo' ||
              (type === 'flexible' && <div className="mt-2 mb-4 font-600">{description}</div>)}
          </div>
        </>
      )}

      {type === 'logo' && <Image src={image} alt={imageAlt} />}
    </article>
  )
}

Card.propTypes = {
  image: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(CardTypes))
}

export default Card
