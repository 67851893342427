const getPage = (page) => {
  if (!page.pageBase && !page.url) {
    return null
  }

  if (page.url) {
    return page
  }

  return page.pageBase[0]
}

export const ExtractLink = (data, secondary) => {
  if (data.targetPage && data.targetPage.length > 0) {
    const page = data.targetPage[0]
    return getPage(page)
  }

  if (Array.isArray(data) && data.length > 0) {
    const page = data[0]
    return getPage(page)
  }

  if (data && data.url) {
    return data
  }

  return null
}

export const ExtractSubLink = (data, secondary) => {
  if (!data.targetPage) {
    return null
  }
  if (data.targetPage.length > 0) {
    const page = data.targetPage[0]
    if (!page.pageBase) {
      return null
    }
    return page.pageBase[0]
  }
}

export const ExtractImage = (data) => {
  if (data.length > 0) {
    const imageMeta = data[0]
    if (imageMeta.image && imageMeta.image.length > 0) {
      return imageMeta.image[0]
    }
  }
}
