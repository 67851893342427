import PropTypes from 'prop-types'

import styles from './Address.module.scss'
import { PickFirst, PhoneLink } from 'services/utils'
import Link from 'basics/Link'

const Address = ({
  className,
  addressLine1,
  addressLine2,
  city,
  state,
  zipcode,
  phoneNumber,
  directions = false,
  chevron,
  directionsLink
}) => {
  const { abbreviation } = PickFirst(state || []) || {}
  const phoneLink = PhoneLink(phoneNumber)
  const chevronClass = chevron

  return (
    <div className={styles['ds-address']}>
      <p>
        {addressLine1}
        <br />
        {addressLine2 && (
          <>
            {addressLine2}
            <br />
          </>
        )}
        {city && (
          <>
            {city}, {abbreviation} {zipcode}
            <br />
          </>
        )}
        {directions && (
          <Link href={directionsLink} external={true} className={'chevron ' + chevronClass}>
            Get Directions
          </Link>
        )}
        {phoneNumber && (
          <a href={phoneLink}>
            <strong className={'highlight ' + chevronClass}>{phoneNumber}</strong>
          </a>
        )}
      </p>
    </div>
  )
}

Address.propTypes = {
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.array,
  zipPostalCode: PropTypes.string,
  phoneNumber: PropTypes.string
}

export default Address
