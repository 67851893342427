export default {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  plum: 'plum',
  blue: 'blue',
  green: 'green',
  orange: 'orange',
  primaryLight: 'plum'
}
