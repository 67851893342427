import PropTypes from 'prop-types'

import styles from './Headline.module.scss'
import SingleColumn from '../../layouts/SingleColumn/SingleColumn'
import Spacer from '../../basics/Spacer'
import Heading from '../../basics/Heading'
import { PickFirst } from 'services/utils'
import { nanoid } from 'nanoid'
import { ClassNames } from 'services/classname.service'

const Headline = ({
  className,
  headline,
  subheadline,
  children,
  link,
  linkText,
  data = {},
  narrow = true,
  level = 2,
  id
}) => {
  const { headline: dynamicHeadline, subheadline: dynamicSubheadline, cta = [] } = data
  return (
    <SingleColumn
      className={ClassNames(['text-align-center', className])}
      containerOptions={{ narrow: true }}
      id={id}
    >
      <Heading level={parseInt(level)}>{headline || dynamicHeadline}</Heading>
      {subheadline && <p className="intro-paragraph mt-3">{subheadline}</p>}
      {dynamicSubheadline && <p className="intro-paragraph mt-3">{dynamicSubheadline}</p>}
      <Spacer size={3} />
      {children && <div className="font-large">{children}</div>}
      {link && linkText && (
        <>
          <div className="text-align-center mt-2">
            <a
              href={link.includes('http') || link[0] === '/' ? link : `/${link}`}
              className="chevron"
            >
              {linkText}
            </a>
          </div>
        </>
      )}
      {cta &&
        cta.map((cta) => {
          return (
            <React.Fragment key={nanoid()}>
              <div className="text-align-center mt-2">
                <a
                  href={cta.linkUrl || `/${PickFirst(cta.linkedItem)?.url || '#'}`}
                  className="chevron"
                >
                  {cta.text}
                </a>
              </div>
            </React.Fragment>
          )
        })}
    </SingleColumn>
  )
}

Headline.propTypes = {
  headline: PropTypes.string,
  subheadline: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string
}

export default Headline
