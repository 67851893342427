import PropTypes from 'prop-types'

import styles from './LargeIcon.module.scss'
import { ClassNames } from '../../services/classname.service'

import Colors from '../../constants/colors'
import Sizes from '../../constants/sizes'

const LargeIcon = ({
  className,
  svg,
  icon = 'diamond',
  type = 'svg',
  color = 'blue',
  size = 'normal'
}) => {
  return (
    <div
      className={ClassNames([
        styles['ds-large-icon'],
        styles[`ds-large-icon--${Sizes[size]}`],
        color !== 'none' ? styles[`ds-large-icon--${Colors[color]}`] : null,
        className
      ])}
    >
      {!svg && type === 'image' && <img src="/icon-1.png" />}
      {!svg && type === 'svg' && <img src={`/${icon}.svg`} />}
      {svg && (
        <div className={styles['ds-large-icon__svg']} dangerouslySetInnerHTML={{ __html: svg }} />
      )}
    </div>
  )
}

LargeIcon.propTypes = {
  color: PropTypes.oneOf(['blue', 'green', 'orange', 'plum', 'primaryLight']),
  type: PropTypes.oneOf(['image', 'svg']),
  size: PropTypes.oneOf(['normal', 'small'])
}

export default LargeIcon
