import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'

import styles from './Pagination.module.scss'
import { ClassNames } from 'services/classname.service'

const Pagination = ({
  className = '',
  pageSize = 0,
  currentPage = 1,
  totalPages = 0,
  onPage = () => {}
}) => {
  let pages = totalPages > 0 ? Array(totalPages).fill(1) : []
  if (totalPages > 1) {
    return (
      <div
        className={ClassNames([
          styles[`ds-pagination`],
          `display-flex ${className ? className : null}`
        ])}
      >
        <ReactPaginate
          pageCount={totalPages}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          pageClassName={styles[`ds-pagination__page-number`]}
          breakClassName={styles[`ds-pagination__page-number`]}
          activeClassName={styles[`ds-pagination__page-number--active`]}
          onPageChange={(selected) => onPage(selected.selected + 1)}
          previousClassName={ClassNames([styles[`ds-pagination__page-number`], styles.prev])}
          nextClassName={ClassNames([styles[`ds-pagination__page-number`], styles.next])}
          nextLabel={
            <>
              <span aria-hidden="true" focusable="false">
                ›
              </span>
              <span className="sr-only">Next page</span>
            </>
          }
          previousLabel={
            <>
              <span aria-hidden="true" focusable="false">
                ›
              </span>
              <span className="sr-only">Previous page</span>
            </>
          }
          forcePage={currentPage}
        />
        {/* {currentPage > 0 && (
          <button
            onClick={() => onPage(currentPage)}
            className={ClassNames([styles[`ds-pagination__page-number`], styles.prev])}
          >
            <span> › </span>
          </button>
        )}
        {totalPages <= 10 && (
          <span className={styles['ds-pagination__pages']}>
            {pages.map((page, idx) => {
              return (
                <span
                  onClick={() => {
                    onPage(idx + 1)
                  }}
                  className={`${styles[`ds-pagination__page-number`]} px-sm py-xxs ${
                    idx + 1 == currentPage ? styles.active : null
                  }`}
                  key={`page-${idx}`}
                >
                  {idx + 1}
                </span>
              )
            })}
          </span>
        )}

        {totalPages >= 10 && (
          <span className={styles['ds-pagination__pages']}>
            {currentPage > 2 && (
              <span
                onClick={() => {
                  onPage(1)
                }}
                className={`${styles[`ds-pagination__page-number`]} px-sm py-xxs ${
                  1 == currentPage ? styles[`ds-pagination__page-number--active`] : null
                }`}
                key={`page-${1}`}
              >
                1
              </span>
            )}
            {currentPage > 2 && (
              <span className={`${styles[`ds-pagination__page-number`]} px-sm py-xxs`}>...</span>
            )}
            {currentPage >= 2 && (
              <span className={`${styles[`ds-pagination__page-number`]}  px-sm py-xxs`}>
                {currentPage}
              </span>
            )}
            {currentPage < totalPages - 5 &&
              pages.slice(currentPage + 1, currentPage + 6).map((_, idx) => {
                const page = currentPage + idx
                return (
                  <span
                    onClick={() => {
                      onPage(page + 1)
                    }}
                    className={`${styles[`ds-pagination__page-number`]} px-sm py-xxs ${
                      page == currentPage ? styles[`ds-pagination__page-number--active`] : null
                    }`}
                    key={`page-${idx}`}
                  >
                    {page + 1}
                  </span>
                )
              })}

            {currentPage < totalPages - 5 && (
              <span className={`${styles[`ds-pagination__page-number`]} px-sm py-xxs`}>...</span>
            )}
            {currentPage < totalPages - 5 && (
              <span
                onClick={() => {
                  onPage(totalPages)
                }}
                className={`${styles[`ds-pagination__page-number`]} px-sm py-xxs ${
                  totalPages == currentPage ? styles[`ds-pagination__page-number--active`] : null
                }`}
                key={`page-${totalPages}`}
              >
                {totalPages}
              </span>
            )}
            {currentPage + 1 < totalPages && (
              <button
                onClick={() => onPage(currentPage + 2)}
                className={ClassNames([styles[`ds-pagination__page-number`], styles.next])}
              >
                <span> › </span>
              </button>
            )}
          </span>
        )} */}
      </div>
    )
  }
  return <div></div>
}

Pagination.propTypes = {
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  onPage: PropTypes.func
}

export default Pagination
